<template>
	<div>
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th> {{ translate('credit_card') }} </th>
						<th> {{ translate('name_on_card') }} </th>
						<th
							class="align-middle">
							{{ translate('add') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td>
							<img
								:key="index"
								:src="require(`@/assets/images/common/cc/card/${cardLogo(item.attributes.card_type)}.png`)"
								class="text-right mr-2">
							<p class="d-inline">
								{{ translate('last_digits') }} <b>{{ item.attributes.last_digits ? item.attributes.last_digits : 'XXXX' }} </b>
							</p>
						</td>
						<td>
							{{ item.attributes.cardholder }}
						</td>
						<td class="align-middle">
							<input
								v-model="cardIds[item.id]"
								class="pointer"
								type="checkbox">
						</td>
					</tr>
				</tbody>
			</table>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:has-data="data.length > 0"
				:no-data-label="translate('no_credit_card')"
				:class="loading ? 'mx-auto' : 'w-100'" />
		</div>
		<div class="row mb-2">
			<div class="col d-flex justify-content-end">
				<button
					:disabled="cardIds.length === 0"
					class="btn btn-primary"
					@click="saveCards">
					<template v-if="!loading">
						{{ translate('save') }}
					</template>
					<template v-else>
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
					</template>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { Profile } from '@/translations';
import IsLoading from '@/components/Loading';
import CreditCard from '@/mixins/CreditCard';

export default {
	name: 'SelectBackupCardsGrid',
	messages: [Profile],
	components: { IsLoading },
	mixins: [CreditCard],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			cardIds: [],
			ids: [],
		};
	},
	watch: {
		cardIds() {
			this.ids = [];
			this.cardIds.forEach((value, key) => {
				if (value) {
					this.ids.push(key);
				}
			});
		},
	},
	mounted() {
		this.cardIds = [];
	},
	methods: {
		saveCards() {
			this.$emit('saveBackupCards', this.ids);
		},
	},
};
</script>
